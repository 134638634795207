import cx from 'classnames';
import React from 'react';
import { Col } from 'react-grid-system';
import styles from './ColumnContent.module.scss';

const ColumnContent = props => {
  return (
    <Col xs={12} sm={12} md={6}>
      <div
        className={cx(
          props.className,
          styles.column,
          props.width === 'narrow' && styles.narrow
        )}
      >
        {props.children}
      </div>
    </Col>
  );
};

export default ColumnContent;
