import cx from 'classnames';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import styles from './TwoColumnList.module.scss';

const ListItems = ({ children }) => <div>{children}</div>;

const ListItem = ({ children }) => (
  <div className={styles.listItem}>{children}</div>
);
const TwoColumnList = props => {
  return (
    <>
      <Row className={styles.listRow}>
        {props.children.map((children, index) => (
          <Col
            key={index}
            className={cx(styles.listItems, index === 1 && styles.lastColumn)}
            sm={12}
            md={6}
          >
            <ListItems>
              {children.props.children.map((child, index) => (
                <ListItem key={index}>{child}</ListItem>
              ))}
            </ListItems>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default TwoColumnList;
