import React, { useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import rehypeReact from 'rehype-react';
import ColumnContent from '../components/ColumnContent/ColumnContent';
import ColumnsContent from '../components/ColumnsContent/ColumnsContent';
import ContentBanner from '../components/ContentBanner/ContentBanner';
import Layout from '../components/Layout/Layout';
import BurgerMenu from '../components/Menu/BurgerMenu';
import Pagination from '../components/Pagination/Pagination';
import Palette from '../components/Palette/Palette';
import Testimonial, {
  TestimonialHeader,
} from '../components/Testimonial/Testimonial';
import Testimonials from '../components/Testimonials/Testimonials';
import TwoColumnList from '../components/TwoColumnList/TwoColumnList';
import styles from './work-template.module.scss';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    palette: Palette,
    'header-banner': ContentBanner,
    'content-column': ColumnContent,
    'content-row': ColumnsContent,
    'post-palette': Palette,
    'post-pagination': Pagination,
    testimonials: Testimonials,
    testimonial: Testimonial,
    'testimonial-header': TestimonialHeader,
    'two-column-list': TwoColumnList,
  },
}).Compiler;

function Template(props) {
  const [isMenuVisible, setMenuState] = useState(false);

  const handleToggleMenu = () => {
    setMenuState(!isMenuVisible);
  };
  return (
    <ParallaxProvider>
      <Layout isMenuVisible={isMenuVisible} menuClick={handleToggleMenu}>
        <BurgerMenu
          active={isMenuVisible}
          onClick={handleToggleMenu}
          asCSS
          style={styles.burgerMenu}
        />
        <div className="post-wrapper">
          {renderAst(props.pageContext.markup)}
        </div>
        <Pagination client={props.pageContext.nextPost.node.frontmatter} />
      </Layout>
    </ParallaxProvider>
  );
}

export default Template;
