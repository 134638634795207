import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { Container, Row } from 'react-grid-system';
import styles from './ColumnsContent.module.scss';

const ColumnsContent = props => (
  <React.Fragment>
    {props.showlogo === 'true' && (
      <div style={{ position: 'sticky', top: '0px', zIndex: 100 }}>
        <div style={{ position: 'relative' }}>
          <a
            className={styles.tiltedEmailText}
            href="mailto: hello@fablefaber.com"
          >
            hello@fablefaber.com
          </a>
          <Link to="/">
            <div className={cx(styles.logo, styles.dark)} />
          </Link>
        </div>
      </div>
    )}
    <div
      id="content-columns"
      className={cx(
        props.className,
        styles.columns,
        props.line === 'on' && styles.line
      )}
    >
      <Container fluid>
        <Row>{props.children}</Row>
      </Container>
    </div>
  </React.Fragment>
);

export default ColumnsContent;
