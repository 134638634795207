import React from 'react';
import { Col } from 'react-grid-system';
import styles from './Testimonial.module.scss';

export const TestimonialHeader = ({ primary, secondary, children }) => (
  <div className={styles.header}>
    <div className={styles.imageWrapper}>{children}</div>
    <div className={styles.feature}>
      <div>{primary}</div>
      <div>{secondary}</div>
    </div>
  </div>
);

const Testimonial = props => {
  return (
    <Col sm={12} lg={6}>
      <div className={styles.testimonial}>{props.children}</div>
    </Col>
  );
};

export default Testimonial;
