import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './Pagination.module.scss';

export default props => {
  if (!props.client) {
    return null;
  }

  const {
    client_name,
    path,
    slogan,
    tags,
    pagination,
    category,
  } = props.client;

  return (
    <div className={styles.pagination} id="pagination">
      <div
        className="down-anchor"
        style={{
          position: 'absolute',
          top: '-100px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <DownAnchor to="pagination" />
      </div>
      <Link to={path} style={{ overflow: 'hidden', width: '100%' }}>
        <div className={styles.linkInner}>
          <div className={styles.detailsWrapper}>
            <div className={styles.noFlex}>
              <div className={styles.next}>Next case study</div>

              <div className={styles.detailsContainer}>
                <div className={styles.overview}>
                  <h4 className={styles.title}>{slogan}</h4>
                  <div className={styles.client}>{client_name}</div>
                  <div className={styles.subTitle}>
                    <p>{category}</p>
                  </div>
                </div>
                <div className={styles.splitter}>
                  <ul className={styles.featureList}>
                    {tags &&
                      tags.map((tag, index) => (
                        <li key={index}>
                          <p>{tag}</p>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.imageWrapper}>
            <Img
              cropFocus="inside"
              quality={100}
              className={styles.image}
              fluid={pagination.childImageSharp.fluid}
              imgStyle={{
                width: '100%',
              }}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
