import React from 'react';
import { Row } from 'react-grid-system';
import Testimonial from '../Testimonial/Testimonial';
import styles from './Testimonials.module.scss';

const Testimonials = props => {
  return (
    <>
      <div className={styles.columns}>
        <div className={styles.sectionTitle}>TESTIMONIAL</div>
        <Row>
          {props.children.map((child, index) => (
            <Testimonial key={index}>{child}</Testimonial>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Testimonials;
