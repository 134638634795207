import cx from 'classnames';
import React, { useState } from 'react';
import { Visible } from 'react-grid-system';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Waypoint } from 'react-waypoint';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './ContentBanner.module.scss';

function ContentBanner({ children, title, shifted }) {
  const [isArrowVisible, setArrowVisible] = useState(false);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Waypoint
        onEnter={() => setArrowVisible(true)}
        onLeave={() => setArrowVisible(false)}
        topOffset={-100}
      />
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.paraWrapper}>
        <ParallaxBanner
          className={styles.para}
          slowerScrollRate
          layers={[
            {
              amount: -0.3,
              expanded: false,
              children: (
                <div className={styles.contentBanner}>
                  <div className={styles.titleGap} />
                  <div
                    className={cx(
                      styles.image,
                      shifted === 'true' && styles.imageAdjusted
                    )}
                  >
                    {children}
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
      {isArrowVisible && (
        <Visible xl lg md>
          <div
            className="down-anchor"
            style={{
              transform: 'translateY(0) translateX(-50%)',
              position: 'absolute',
              bottom: '0',
              left: '50%',
              zIndex: 100,
            }}
          >
            <DownAnchor to="content-columns" offset={-250} />
          </div>
        </Visible>
      )}
    </div>
  );
}

export default ContentBanner;
